@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific

@import 'react/index';

// ** WE USE TO CUSTOMIZE SCROLLBAR

::-webkit-scrollbar {
    width: 1em;
    height: 1em;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 100vw;
    margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
    background: #FFC300;
    border: 0.25em solid #FFC300;
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
    background: #FFC300;
    border: 0.25em solid #FFC300;
}

// // ** WE USE TO CUSTOMIZE SCROLLBAR

// // ** WE USE TO CUSTOMIZE CARDS 
.EfectTransLateY {
    &:hover {
        transform: translateY(-5px);
        transition: 0.3s;
    }
}

// We use this image to show in login
.imageLoginBackgroundFromCoreCss {
    background-image: url("../../assets/images/pages/login-v2-dark.png");
    background-repeat: no-repeat;
}

// We use to change color in customizedTable
.changeIconColorFromCss:hover {
    color: #FFC300;
}